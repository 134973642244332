// /*jslint browser: true */

// Lenis
const lenis = new Lenis({ lerp: 0.3 });

lenis.on('scroll', () => ScrollTrigger.update());

function raf(time) { 
  lenis.raf(time); 
  requestAnimationFrame(raf); 
}
requestAnimationFrame(raf);

// Menu Active Section
function highlightActiveLink() {
  // Get all the menu links and sections
  const menuLinks = document.querySelectorAll('.anchor-link');
  const sections = document.querySelectorAll('article > section');

  // Create a ScrollTrigger for each section
  sections.forEach(section => {
    ScrollTrigger.create({
      trigger: section,
      start: 'top center-=20%',
      end: 'bottom center-=20%',
      onEnter: () => setActiveLink(section),
      onEnterBack: () => setActiveLink(section),
      onLeave: () => removeActiveLink(section),
      onLeaveBack: () => removeActiveLink(section),
    });
  });

  // Set active class to the corresponding menu link
  function setActiveLink(section) {
    menuLinks.forEach(link => {
      if (link.getAttribute('href').substring(1) === section.id) {
        link.classList.add('active');
      }
    });
  }

  // Remove active class from the menu link
  function removeActiveLink(section) {
    menuLinks.forEach(link => {
      if (link.getAttribute('href').substring(1) === section.id) {
        link.classList.remove('active');
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', function() {

  highlightActiveLink();

});